<template>
<v-row class="mt-0 ml-3 mr-3">
    <v-col cols="12" class="pb-0 pt-0">
        <div class="d-flex justify-space-between pb-2 pt-2 pr-1" style="box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;">
                    <search-customer-seller :showGoback="false"/> 
            <div class="d-flex flex-wrap">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="#C82333" class="mr-5" size="40">mdi-notebook-edit</v-icon>
                    </template>
                    <span>Directorio</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="black" class="mr-5" size="40">mdi-notebook</v-icon>
                    </template>
                    <span>Agenda</span>
                </v-tooltip>
            </div>
        </div>
    </v-col>
    <v-col cols="12" class="pb-0 pt-0">
        <average-orders 
        :chnl="chnl"
        :regionalManager="regionalManager"   />
    </v-col>
    <v-col cols="12" class="pb-0 pt-0">
        <new-products 
        :chnl="chnl"
        :regionalManager="regionalManager"   />
    </v-col>
    <v-col cols="12" class="pb-0 pt-0">
        <new-products 
        :chnl="chnl" 
        title="FACTURACIÓN PRODUCTOS GENÉTICA N" 
        :isGen="1" 
        :regionalManager="regionalManager"  />
    </v-col>
    <v-col cols="12" class="pb-0">
  
        <div class="d-flex flex-wrap ma-0 w-100" style="box-shadow: inset 0px 0px 8px #FE080829, 0px 0px 6px #F8090929;"> 
            <div class="pa-2 pl-10 d-flex flex-wrap">
              <div class="mt-2 mr-1 "> Agrupar por :</div>
              <div class="mt-2" style="width: 180px;">
                <custom-gray-select @input="changeGroupBy" style="width: 100%;" v-model="group" :options="groupByOptions" />
              </div> 
            </div>
            <div class="pa-2 pl-10 d-flex flex-wrap">
              <div class="mt-2 mr-1 ">Periodo:</div> 
              <div class="mt-2">
                <custom-gray-select @input="changePeriod" v-model="periodDate"  :options="optionPeriods" />
              </div> 
            </div>
          </div>
    </v-col>
    <v-col cols="12" class="pb-0 pt-0">
        <seller-list 
        :chnl="chnl" 
        :regionalManager="regionalManager"
        :period="periodDate"
        />
    </v-col>
</v-row>
</template>

<script>
 import { mapGetters } from "vuex";
 import AverageOrders from "./AverageOrders.vue";
 import NewProducts from "./NewProducts.vue";
 import CustomGraySelect from "@/components/CustomGraySelect.vue";
 import SellerList from "@/views/diary/director/zone/SellerList.vue";
 import SearchCustomerSeller from "@/components/SearchCustomerSeller.vue";
 import MixinService from '@/mixins/service'
import MixinHelpers from '@/mixins/helpers';
import MixinDates from '@/mixins/dates'; 
 export default {
     name: "DirectorHome",
     components: {
         AverageOrders,
         NewProducts,
         CustomGraySelect,
         SellerList,
         SearchCustomerSeller
     },
    mixins:[MixinService,MixinHelpers,MixinDates],
     props: {
         groupByOptions:{
            required:true, 
         },
         groupBy: {
             default: 'Zona'
         },
         period: {
             required: true, // format = 'Mayo/2023'
         },
         chnl:{
          required:true,
          String
         },
         regionalManager:{
          required:true,
      }
     },
     data: () => ({
         periodDate:[],
         group: 'Zona',
         months:[],
     }),
     watch: {},
     methods: {  
         changePeriod() {
             const data = {
                 groupBy: this.group,
                 period: this.periodDate
             }
             this.periodDate
             this.$emit('groupByChange', data)
         },
         changeGroupBy() {
             const data = {
                 groupBy: this.group,
                 period: this.periodDate
             }
             this.$emit('groupByChange', data)
         },
     },
     mounted(){
        this.months = this.calcularUltimosMeses(13);
        this.periodDate = this.months[0].date;
      },
     computed: {
         ...mapGetters({
             authenticated: "auth/authenticated",
             user: "auth/user",
         }),
         optionPeriods(){  
        return this.arrayPluck(this.months,'date');
      },
     }
 };
</script>
